export const options = {
  needsTypeOptions: [
    {text: '找服务商', value: 1},
    {text: '找制作商', value: 2},
    {text: '异业合作', value: 3},
    {text: '找人', value: 4},
    {text: '找其他', value: 5}
  ],
  needsDaysOptions: [
    {text: '所有未结束', value: null},
    {text: '今日需求', value: 1},
    {text: '三天需求', value: 3},
    {text: '一周需求', value: 7},
    {text: '30天需求', value: 30},
  ],
  resourceTypeOptions: [
    {text: '服务制作', value: 1},
    {text: 'IP/道具/设备/供应', value: 2},
    {text: '演艺/人才', value: 3},
    {text: '流量/媒介/传媒', value: 4},
    {text: '场地/其他', value: 5},
  ],
  resourceDaysOptions: [
    {text: '所有未结束', value: null},
    {text: '今日资源', value: 1},
    {text: '三天资源', value: 3},
    {text: '一周资源', value: 7},
    {text: '30天资源', value: 30},
  ],
  rewardTypeOptions: [
    {text: '提供什么', value: null},
    {text: '付费', value: 1},
    {text: '资源置换', value: 2},
    {text: '视资源而定', value: 3}
  ],
  resourceNeedsTypeOptions: [
    {text: '需要什么', value: null},
    {text: '收费', value: 1},
    {text: '资源置换', value: 2},
    {text: '视资源而定', value: 3}
  ],
}


export const publishNeedsOptions = {
  identityTypeOptions: [
    {name: '我是谁', value: null, isNotOption: true},
    {name: '品牌方', subname: '互联网产品/实体产品/IP', value: 1},
    {name: '服务商', subname: '代理商/服务商/执行公司', value: 2},
    {name: '制作商', subname: '物料/道具/搭建/茶歇', value: 3},
    {name: '流量方', subname: '媒体/渠道', value: 4},
    {name: '场地方', subname: '', value: 5},
    {name: '从业者', subname: '方案写手/设计师/其他专业人士', value: 6},
  ],

  needsTypeOptions: [
    {name: '需要什么', value: null, isNotOption: true},
    {name: '找服务商', value: 1},
    {name: '找制作商', value: 2},
    {name: '异业合作', value: 3},
    {name: '找人', value: 4},
    {name: '找其他', value: 5},
  ],

  rewardTypeOptions: [
    {name: '提供什么', value: null, isNotOption: true},
    {name: '付费', value: 1},
    {name: '资源置换', value: 2},
    {name: '视资源而定', value: 3},
  ],

}



export const publishBizResourceOptions = {
  identityTypeOptions: [
    {name: '我是谁', value: null, isNotOption: true},
    {name: '品牌方', subname: '互联网产品/实体产品/IP', value: 1},
    {name: '服务商', subname: '代理商/服务商/执行公司', value: 2},
    {name: '制作商', subname: '物料/道具/搭建/茶歇', value: 3},
    {name: '流量方', subname: '媒体/渠道', value: 4},
    {name: '场地方', subname: '', value: 5},
    {name: '从业者', subname: '方案写手/设计师/其他专业人士', value: 6},
  ],

  resourceTypeOptions: [
    {name: '提供什么', value: null, isNotOption: true},
    {name: '服务制作', value: 1},
    {name: 'IP/道具/设备/供应', value: 2},
    {name: '演艺/人才', value: 3},
    {name: '流量/媒介/传媒', value: 4},
    {name: '场地/其他', value: 5},
  ],

  needsTypeOptions: [
    {name: '需要什么', value: null, isNotOption: true},
    {name: '收费', value: 1},
    {name: '资源置换', value: 2},
    {name: '视资源而定', value: 3},
  ],
}
