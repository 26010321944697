<template>
  <div>
    <div @click="show">
      <slot></slot>
    </div>
    <van-popup position="bottom" v-model="visible" round>
      <van-area columns-num="2" @cancel="hide" @confirm="onConfirm" title="标题" :area-list="areaList" :value="cityCode"/>
    </van-popup>
  </div>
</template>

<script>
import {areaList} from '@vant/area-data';

export default {
  props: {
    province: {type: String},
    city: {type: String},
  },

  computed:{

    cityCode(){
      let cityCode = ''
      for(const key in areaList.city_list){
        const name = areaList.city_list[key]
        if(name === this.city){
          cityCode = key
        }
      }
      return cityCode
    }

  },

  data() {
    return {
      visible: false,
      areaList
    }
  },

  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },


    onConfirm(e) {
      this.hide()
      this.$emit('update:province',e[0].name)
      this.$emit('update:city',e[1].name)
    }
  }
}

</script>

<style scoped lang="less">

</style>
