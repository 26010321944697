// import OSS from 'ali-oss';
import {post} from './api.config';


const OSS_CONFIG = {
  region: 'oss-cn-guangzhou',
  bucket: 'lingganyanxuan147',
  // bucket: 'lgyx-test'
};


/**
 * 分片上传文件
 * @param {File} file 文件，通过 input[type=file]获得
 * @param {String} objectName 上传至bucket中的文件位置
 * @param {EventEmitter} eventEmitter 时间监听器，将发送 progress事件
 */
export async function multipartPutObject(file, objectName, eventEmitter = null) {
  // ① 请求服务器获取上传的临时凭证 sts
  // ② 调用 oss.putObject()，携带sts凭证
  // const {code, data} = await fetch('getOssToken', 'post');
  // if (code !== 1) {
  //     return {code};
  // }
  const {code, data} = await post(`web/upload/token`);
  const ossClient = new OSS({
    ...OSS_CONFIG,
    ...data,
    stsToken: data.securityToken
  });
  if (code === 1) {
    return await ossClient.multipartUpload(objectName, file);
  }
}


export function computeObjectFullPath(objectName) {
  const {region, bucket} = OSS_CONFIG;
  return `https://${bucket}.${region}.aliyuncs.com/${objectName}`;
}
