import { render, staticRenderFns } from "./needs.vue?vue&type=template&id=66b89496&scoped=true&"
import script from "./needs.vue?vue&type=script&lang=js&"
export * from "./needs.vue?vue&type=script&lang=js&"
import style0 from "./needs.vue?vue&type=style&index=0&id=66b89496&scoped=true&lang=less&"
import style1 from "./needs.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b89496",
  null
  
)

export default component.exports