<template>
  <div>
    <div @click="show" class="select">
      <div class="label">{{ currOption && currOption.name && currOption.name.length ? currOption.name : '请选择' }}
      </div>
      <van-icon name="arrow-down"/>
    </div>
    <van-action-sheet :title="title||'请选择'" v-model="visible" closeable :actions="innerOptions"
                      @select="onSelect"></van-action-sheet>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array
    },
    title: {
      type: String
    },
    value: {
      type: String | Number
    },
    nameKey: {
      type: String
    }
  },


  computed: {
    innerOptions() {
      const options = this.options || []
      const rst = []
      for(const o of options){
        const obj = Object.assign({}, o)
        if(this.nameKey && this.nameKey.length){
          obj.name = obj[this.nameKey]
        }
        rst.push(obj)
      }
      return rst
    },


    currOption() {
      const options = this.options || []
      return options.find(o => o.value === this.value) || {}
    }

  },


  data() {
    return {
      visible: false
    }
  },

  methods: {
    show() {
      this.visible = true
    },


    hide() {
      this.visible = false
    },


    onSelect(e) {
      this.hide()
      this.$emit('input', e.value)
    }
  }


}
</script>

<style scoped lang="less">
.select {
  background: rgba(21, 29, 54, 0.03);
  height: 32px;
  font-size: 12px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
}

</style>
