<template>
  <div class="publish-page-body">
    <!--身份类型 需求方简称-->
    <div class="publish-line">
      <div class="publish-line-picker">
        <div class="red"></div>
        <div class="picker">
          <action-picker title="我是谁" v-model="model.identityType" :options="identityTypeOptions"/>
        </div>
      </div>
      <input v-model="model.company" placeholder="需求方简称"/>
    </div>

    <!--需求类型 需求描述-->
    <div class="publish-line">
      <div class="publish-line-picker">
        <div class="red"></div>
        <div class="picker">
          <action-picker title="需要什么" v-model="model.needsType" :options="needsTypeOptions"/>
        </div>
      </div>
      <input type="text" v-model="model.desc" placeholder="需求描述"/>
    </div>


    <!--回报类型 回报描述-->
    <div class="publish-line">
      <div class="publish-line-picker">
        <div class="red"></div>
        <div class="picker">
          <action-picker title="提供什么" v-model="model.rewardType" :options="rewardTypeOptions"/>
        </div>
      </div>
      <input type="text" v-model="model.rewardDesc" placeholder="回报描述"/>
    </div>


    <div class="publish-line import-btn-switch" @click="visibles.importBtn=!visibles.importBtn">
      上传介绍文档 / 图片
      <img src="@/assets/svg/right-triango.svg"
           :style="visibles.importBtn?'transform:rotate(-90deg)':'transform:rotate(90deg)'">
    </div>

    <div class="import-btn" v-if="visibles.importBtn">
      <!--      <div class="button" @click="importWxFile">-->
      <!--        <van-icon name="plus" />-->
      <!--        <div style="margin-left: 4px">导入聊天记录(PPT等)</div>-->
      <!--      </div>-->
      <div style="height: 1px;width: 9px;"></div>
      <div class="button" @click="visibles.imgList=!visibles.imgList">
        <van-icon name="plus"/>
        <div style="margin-left :4px">上传图片(3张内)</div>
      </div>
    </div>
    <!--    <div class="imported-line" v-if="model.introduceFileName && model.introduceFileUrl && visibles.importBtn">-->
    <!--      <div class="imported-file">-->
    <!--        <img src="@/assets/svg/file.svg" style="width:15px;height: 27px;margin-right:4px" alt="">-->
    <!--        <div>{{model.introduceFileName}}</div>-->
    <!--      </div>-->
    <!--      <van-icon name="cross" @click="removeIntroduceFile"/>-->
    <!--    </div>-->
    <div class="upload-line-imgs" v-if="visibles.imgList && visibles.importBtn">
      <van-uploader @delete="deleteImg" multiple :max-count="3" :after-read="uploadImg" :file-list="imgList"/>
    </div>



    <!--合作地区-->
    <div class="publish-line">
      <div class="publish-line-label"><div style="margin-left: 11px"></div>合作地区：</div>
      <div>
        <city-picker bindchange="setCity" :province.sync="model.province" :city.sync="model.city">
          <div class="publish-line-city"  v-if="model.province&&model.city">
            <div style="white-space: nowrap;width: 91px;overflow: hidden;text-overflow: ellipsis">{{model.province}}/{{ model.city }}</div>
            <van-icon name="clear" style="color: #999;font-size: 16px" @click="clearCity"/>
          </div>
          <div class="publish-line-city" v-else>
            <div>选择地区</div>
            <van-icon name="arrow-down" />
          </div>
        </city-picker>
      </div>
      <input class="city-desc" type="text" v-model="model.cityDesc" placeholder="地区描述"/>
    </div>


    <!--截止时间-->
    <div class="publish-line">
      <div class="publish-line-label">
        <div class="red"></div>
        截止日期：
      </div>
      <div class="input" bindtap="showCalendar" @click="visibles.expireDateCalendar=true">
        {{ model.expireDate || '停止在商机库发布的日期' }}
        <div>
          <img src="https://cdn.001ppt.cn/wxApp/assets/svg/calendar.svg">
        </div>
      </div>
    </div>


    <!--联系人-->
    <div class="publish-line">
      <div class="publish-line-label">
        <div class="red"></div>
        怎么称呼：
      </div>
      <input type="text" v-model="model.contact" placeholder="X小姐/先生/昵称"/>
    </div>

    <!--联系电话-->
    <div class="publish-line">
      <div class="publish-line-label">
        <div class="red"></div>
        联系电话：
      </div>
      <input type="text" v-model="model.mobile" placeholder="请务必确认能联系上"/>
    </div>

    <!--联系电话-->
    <div class="publish-line">
      <div class="publish-line-label">
        <div class="red"></div>
        联系微信：
      </div>
      <input type="text" v-model="model.wxNum" placeholder="微信号和手机号至少填一项"/>
    </div>


    <div class="submit-btn">
      <div class="button" @click="submit">提交审核</div>
    </div>


    <van-calendar v-model="visibles.expireDateCalendar" @confirm="onChooseExpireDate" color="#151d36"/>
    <city-picker v-model="model.city" ref="cityPicker"/>
  </div>
</template>

<script>
import ActionPicker from "../../../components/actionPicker";
import {publishNeedsOptions} from "../options";
import {computeObjectFullPath, multipartPutObject} from "../../../api/oss";
import {formatDate} from "../../../filters/dateFilter";
import CityPicker from "../../../components/cityPicker";
import {needsV2Api} from "../../../api/v2/needsV2Api";


export default {
  components: {CityPicker, ActionPicker},

  computed: {
    imgList() {
      if (this.model.imgUrls) {
        return JSON.parse(this.model.imgUrls).map(i => ({url: i}))
      }
      return []
    }
  },

  data() {
    return {
      ...publishNeedsOptions,
      visibles: {
        importBtn: false,
        imgList: false,
        expireDateCalendar: false,
      },
      model: {
        id: null,
        identityType: null,    // 身份类型
        needsType: null,       // 需求类型
        rewardType: null,      // 回报类型
        company: '',           // 公司，需求方简称
        desc: '',              // 描述，需求描述
        rewardDesc: '',        // 回报描述
        expireDate: '',        // 截止日期
        contact: '',           // 联系人
        mobile: '',            // 联系方式
        wxNum: '',             // 微信号
        introduceFileName: '', // 介绍文件的文件名
        introduceFileUrl: '',  // 介绍文件的文件url
        province: '',
        city: '',
        cityDesc: '',
        imgUrls: '[]',         // 附图url路径集合，json数组
      },
    }
  },


  methods: {

    async loadModel(id){
      this.model = await needsV2Api.findById(id)
    },

    async uploadImg(e) {
      const date = new Date()
      const file = e.file

      const objectName = `resource/${date.getFullYear()}/${date.getUTCMonth()}/${date.getDate()}/${date.getTime()}${Math.round((Math.random() * 1000)) + ''}${file.name}`
      await multipartPutObject(file, objectName)
      const url = computeObjectFullPath(objectName)
      const imgList = [...this.imgList.map(i => i.url), url]
      this.model.imgUrls = JSON.stringify(imgList)
    },


    deleteImg(e) {
      this.model.imgUrls = JSON.stringify(this.imgList
        .filter(i => i.url !== e.url)
        .map(i => i.url))
    },


    onChooseExpireDate(e) {
      this.model.expireDate = formatDate(e, 'yyyy-MM-dd')
      this.visibles.expireDateCalendar = false
    },

    clearCity(){
      this.model.province = ''
      this.model.city = ''
    },


    // 提交
    async submit() {
      const model = this.model;
      if (!model.identityType) {
        return this.$toast.info('请选择身份类型');
      }
      if (!model.company || !model.company.length) {
        return this.$toast.info('请填写需求方简称');
      }
      if (!model.needsType) {
        return this.$toast.info('请选择需求类型');
      }
      if (!model.desc || !model.desc.length) {
        return this.$toast.info('请填写需求描述');
      }
      if (!model.rewardType) {
        return this.$toast.info('请选择回报类型');
      }
      if (!model.rewardDesc || !model.rewardDesc.length) {
        return this.$toast.info('请填写回报描述');
      }
      // if (!model.introduceFileName || !model.introduceFileName.length || !model.introduceFileUrl || !model.introduceFileUrl) {
      //   return this.$toast.info('请上传介绍文件');
      // }
      if (!model.expireDate || !model.expireDate.length) {
        return this.$toast.info('请填写截止日期');
      }
      if (!model.contact || !model.contact.length) {
        return this.$toast.info('请填写联系人');
      }
      if ((!model.mobile || !model.mobile.length) && (!model.wxNum || !model.wxNum.length)) {
        return this.$toast.info('请至少填写联系电话和微信号其中一项');
      }

      try {
        this.$loading('发布需求中');
        const toSave = {...model};
        toSave.expireDate = toSave.expireDate + ' 00:00:00';
        await needsV2Api.submit(toSave);
        this.$loading.close()
        this.$toast.success('保存成功');
        this.$router.back()
      } catch (e) {
        this.$loading.close()
        this.$toast.fail(e.message)
      }
    }

  }
}
</script>

<style scoped lang="less">


.publish-page-body {
  background: #fff;
  margin: 24px 0;
  padding: 20px 30px;
}

.publish-line {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}

.upload-line {
  margin-bottom: 18px;
}

.upload-line-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 8px 30px;
  background: #f2f2f2;
  border-radius: 4px;
}

.upload-line-label:active {
  background: #e2e2e2;
}

.upload-line-imgs {
  margin-top: 16px;
}

.publish-line-picker, .publish-line-label {
  width: 122px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: 9px;
}

.publish-line-picker .picker {
  flex-grow: 1;
}

.publish-line-label {
  width: 82px;
  flex-shrink: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.publish-line input, .publish-line .input {
  padding: 0 8px;
  font-size: 14px;
  flex-grow: 1;
  flex-shrink: 0;
  background: rgba(21, 29, 54, 0.05);
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.publish-line .input image {
  height: 12px;
  width: 12px;
}

.publish-line .input {
  color: #777;
}

.publish-line input {
  border: none;
  font-size: 14px;
}

.import-btn {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
}

.import-btn .button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 12px;
  background: #f2f2f2;
  border-radius: 4px;
  flex-grow: 1;
}

.import-btn .button:active {
  background: #e2e2e2;
}

.submit-btn {
  display: flex;
  justify-content: center;
}

.submit-btn .button {
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 12px;
  background: #151d36;
  color: #fff;
  border-radius: 100px;
}


button {
  width: 100% !important;
}


.red {
  height: 5px;
  width: 5px;
  border-radius: 50px;
  background: #ee4a50;
  margin-right: 6px;
}


.imported-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0 24px;
  margin-bottom: 18px;
}

.imported-file {
  display: flex;
  align-items: center;
  margin-right: 8px;
}


.publish-line-city {
  font-size: 12px;
  width: 91px;
  margin-right: 10px;
  height: 32px;
  background: rgba(21, 29, 54, 0.03);
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.city-desc {
  width: 50px;
}


.import-btn-switch {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.import-btn-switch img {
  height: 10px;
  width: 10px;
  margin-left: 4px;
  transition: transform .3s;
}

</style>
<style>
.van-calendar .van-button__text{
  color: #fff;
}
</style>
