<template>
  <div class="publish-page">
    <v-header/>
    <van-tabs v-model="currTab">
      <van-tab title="发布需求" name="needs">
        <publish-needs ref="publishNeeds"/>
        <div class="publish-page-tip">
          <div>说明：</div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">1.</div> <div>审核需一个工作日，节假日顺延、审核通过后会展示在【灵感严选】小程序；</div></div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">2.</div> <div>您可在【个人中心】查看审核情况，如有问题可联系客服；</div></div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">3.</div> <div>审核通过后，会免费同步到100+行业社群，曝光1w+同行，一起帮你找；</div></div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">4.</div> <div>灵感严选不做生意，不做背书，只负责小程序运营，双方自行完成供需对接，尤其需要保证信息的真实性。</div></div>
        </div>
      </van-tab>
      <van-tab title="发布资源" name="bizResource">
        <publish-biz-resource ref="publishBizResource"/>
        <div class="publish-page-tip">
          <div>说明：</div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">1.</div> <div>审核需一个工作日，节假日顺延、审核通过后会展示在【灵感严选】小程序；</div></div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">2.</div> <div>您可在【个人中心】查看审核情况，如有问题可联系小助手；</div></div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">3.</div> <div>审核通过后，会免费同步到100+行业社群，曝光1w+同行，一起帮你找；</div></div>
          <div class="publish-page-tip-line"><div class="publish-page-tip-line-order">4.</div> <div>灵感严选不做生意，不做背书，只负责小程序运营，双方自行完成供需对接，尤其需要保证信息的真实性。</div></div>
        </div>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import PublishNeeds from "./needs";
import PublishBizResource from "./bizResource";

export default {
  components:{PublishNeeds,PublishBizResource},

  created() {
    if (this.$route.query.tab) {
      this.currTab = this.$route.query.tab
    }

    setTimeout(()=>{
      if(this.$route.query.id){
        if(this.currTab === 'needs'){
          this.$refs.publishNeeds.loadModel(this.$route.query.id)
        }else if(this.currTab === 'bizResource'){
          this.$refs.publishBizResource.loadModel(this.$route.query.id)
        }
      }
    },10)

  },

  data() {
    return {
      currTab: 'needs'
    }
  },


}
</script>

<style scoped lang="less">
.publish-page{
  padding-top: 40px;
  min-height: calc(100vh - 40px);
  background: #f4f4f4;
}


.publish-page-tip{
  padding: 24px 24px;
  font-size: 12px;
  line-height: 20px;
}

.publish-page-tip-line{
  display: flex;
  word-break: break-all;
}

.publish-page-tip-line-order{
  width: 15px;
  flex-shrink: 0;
}
</style>
